import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, SecurityContext } from '@angular/core';
import { UiLinkModule } from '@big-direkt/ui/link';
import { IconBigBoldOk, IconBigBoldOkKreis, IconBigMediumPfeilRechts, IconComponent } from '@big-direkt/utils/icons';
import { BreakpointService, ListBreakpointSortPipe, Nl2brPipe, SanitizePipe } from '@big-direkt/utils/shared';
import { type ListItemModel } from '../models/list-item.model';
import { ListRendering } from '../models/list-rendering.type';

@Component({
    selector: 'big-ui-list',
    templateUrl: './list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IconComponent, ListBreakpointSortPipe, NgClass, Nl2brPipe, SanitizePipe, UiLinkModule],
})
export class ListComponent {
    public readonly breakpointService = inject(BreakpointService);
    public readonly isMobile = this.breakpointService.isMobile;

    public readonly iconOk = IconBigBoldOk;
    public readonly iconOkCircle = IconBigBoldOkKreis;
    public readonly iconArrowRight = IconBigMediumPfeilRechts;
    public readonly securityContext: SecurityContext = SecurityContext.HTML;

    @Input() public render!: ListRendering;
    @Input() public items: ListItemModel[] = [];
    @Input() public columns: 1 | 2 = 1; // eslint-disable-line @typescript-eslint/no-magic-numbers

    @HostBinding('class') public classList = 'block';

    public get columnClass(): string {
        if (this.columns === 1) {
            return 'grid-cols-1';
        }

        return 'grid-cols-1 md:grid-cols-2';
    }
}
